import Vue from "vue";
import VueRouter from "vue-router";
// import LoginPage from "../views/LoginPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/ingreso",
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      hideForAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginPage.vue"),
  },
  {
    path: "/permisosError",
    name: "PermisosError",
    meta: { requiresAuth: true, permError: true },
    component: () =>
      import(
        /* webpackChunkName: "permisosError" */ "../views/PermisosError.vue"
      ),
  },
  {
    path: "/updateMsg/:minVersion/:lastVersion",
    name: "UpdateMsg",
    props: true,
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "UpdateMsg" */ "../views/UpdateMsg.vue"),
  },
  {
    path: "/ingreso",
    name: "Ingreso",
    props: true,
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "ingreso" */ "../views/Ingreso.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("user");
  let permission = false;
  //console.log("loggedIn?: " + loggedIn);
  if (loggedIn) {
    permission = JSON.parse(loggedIn).permission;
  } else permission = false;

  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next("/login");
  } else if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    to.matched.some((record) => !record.meta.permError) &&
    loggedIn &&
    !permission
  ) {
    next("/permisosError");
  } else if (to.matched.some((record) => record.meta.hideForAuth) && loggedIn) {
    next("/");
  } else next();
});

export default router;

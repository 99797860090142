<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-toolbar-title>ControlTSS - RelojWeb </v-toolbar-title>
      <div class="d-flex align-center"></div>
      <v-spacer></v-spacer>
      <v-menu v-if="username" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn depressed color="primary" dark v-bind="attrs" v-on="on">
            <v-icon left>mdi-devices</v-icon>
            <span class="mr-2">{{ username }}</span>
          </v-btn>
        </template>
        <v-list nav dense>
          <v-list-item key="2" @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Salir</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-container fluid class="container">
        <v-alert dense v-if="error" type="error">{{ error }}</v-alert>
        <router-view />
      </v-container>
    </v-main>

    <v-footer padless app color="primary">
      <v-col class="primary text-center white--text" cols="12">
        {{ new Date().getFullYear() }} © TSS Group SRL - Versión: {{ version }}
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { authComputed } from "./store/helpers.js";
import { version } from "../package";
import RelojService from "@/services/RelojService.js";
import Helpers from "@/helpers/helpers.js";
export default {
  name: "App",

  computed: {
    ...authComputed,
  },
  data: () => ({
    username: null,
    error: null,
    version: null,
  }),
  updated() {
    this.getUserName();
  },
  created() {
    this.version = version;
  },
  methods: {
    logout() {
      RelojService.logout()
        .then((response) => {
          this.$store.dispatch("logout");
        })
        .catch((error) => {
          this.error = Helpers.handleError(error);
          //this.$store.dispatch("logout");
        });
    },

    getUserName() {
      let loggedIn = localStorage.getItem("user");
      if (loggedIn) {
        this.username = JSON.parse(loggedIn).username;
      }
    },
  },
};
</script>
<style scope>
.container {
  /* padding-top: 0 !important; */
}
</style>

import axios from "axios";
// import { mapGetters } from "vuex";
// import moment from "moment";

const loggedIn = localStorage.getItem("user");

const apiClient = axios.create({
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

/*
Re inject the token in axios when 
*/
function initClient(loggedin) {
  if (
    apiClient.defaults.headers.common["Authorization"] &&
    apiClient.defaults.headers.common["Authorization"].length > 8
  )
    return;
  else {
    var userdata = JSON.parse(loggedin);
    apiClient.defaults.headers.common["Authorization"] =
      "Token " + userdata.token;
    if (process.env.NODE_ENV == "development") {
      apiClient.defaults.baseURL = "http://127.0.0.1:8000";
    } else {
      apiClient.defaults.baseURL =
        "https://" + userdata.tenant + ".api.controltss.com.ar";
    }
  }
}

if (loggedIn) initClient(loggedIn);

export default {
  login(user_credentials) {
    if (process.env.NODE_ENV == "development") {
      apiClient.defaults.baseURL = "http://127.0.0.1:8000";
    } else {
      apiClient.defaults.baseURL =
        "https://" + user_credentials.tenant + ".api.controltss.com.ar";
    }
    //delete user_credentials.tenant;
    apiClient.defaults.headers.common["Authorization"] = null;
    return apiClient.post("/auth/", user_credentials);
  },
  setToken(token) {
    apiClient.defaults.headers.common["Authorization"] = "Token " + token;
  },

  /* Reloj */
  logout() {
    return apiClient.get("/auth/LogOut");
  },

  getEmpleadoByDocumento(documentoNro) {
    return apiClient.get(
      "/visitas/personas?documento=" + documentoNro + "&tipo=1"
    );
  },

  getDispositivoByUser(user) {
    return apiClient.get("empleados/dispositivo/byuser/" + user + "/");
  },

  getDispositivoEmpleados(id) {
    return apiClient.get("empleados/dispositivo/" + id + "/empleados/");
  },

  postFichada(fichada) {
    return apiClient.post("empleados/fichada/new", fichada);
  },

  getVersions() {
    return apiClient.get("empleados/dispositivo/versions");
  },
};
